import {motion, useScroll} from "framer-motion";
import Hero from "./Hero";
import Hero2 from "./Hero2";
import Service2 from "./Service2";
import {FAQ} from "./FAQ";
import BlogItem from "./BlogItem";
import Footer from "./Footer";
import React from "react";
import {Helmet} from "react-helmet";
import Header from "./Header";

function Root() {

    const { scrollYProgress } = useScroll();

    return(
        <>
            <Helmet>
                <title>Netbrain - Egyedi weboldal fejlesztés</title>
                <meta name="description" content="netbrain egyedi weboldal fejlesztés" />
            </Helmet>
            <Header/>
            <motion.div
                className="progress-bar"
                style={{ scaleX: scrollYProgress }}
            />
            <Hero/>
            <Hero2/>
            <Service2/>
            <FAQ/>
            <BlogItem/>
            <Footer/>
        </>
    )
}

export default Root
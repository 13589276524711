import {motion, useScroll} from "framer-motion";
import {Helmet} from "react-helmet";
import React from "react";
import Footer from "./Footer";
import {Box, Container, Typography} from "@mui/material";
import Header from "./Header";

function BlogRefresh() {

    const { scrollYProgress } = useScroll();

    return(
        <>
            <Helmet>
                <title>Netbrain Blog - Weblap frissítés</title>
                <meta name="description" content="netbrain egyedi weboldal fejlesztés" />
            </Helmet>
            <motion.div
                className="progress-bar"
                style={{ scaleX: scrollYProgress }}
            />
            <Header/>
            <Container className="blog-page">
                <Typography component="h1" variant="h4" gutterBottom>
                    Miért kell rendszeres honlap karbantartás? Veszélyes is lehet egy nem frissített weblap?
                    Rendszeres vagy alkalmi frissítésre van szükség? Weboldal felújítást vagy egy új készítését
                    válaszd?
                </Typography>
                <Typography component="p" variant="body1" gutterBottom className="hero1">
                    Ebben a cikkben végignézzük, hogy mikor szorul a holnapod karbantartásra, illetve az alábbi
                    kérdésekre keressük a választ: Van-e valami szabálya a rendszeres, illetve az alkalmi
                    frissítésnek? Milyen veszélyeket rejthet egy elavult honlap? Mikor érdemes új weboldalban
                    gondolkodni? Évente új honlap kell? Meddig jó egyáltalán egy honlap?
                </Typography>
                <Box className="center">
                    <Box component="img" src="/blog_refresh.jpg" className="blog-image"/>
                </Box>
                <Typography component="h3" variant="h4" gutterBottom>
                    Rendszeres karbantartás és frissítés
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Gyakori tévhit, hogy ha elkészült a honlapod, akkor már hátra lehet dőlni, és többet nem kell
                    törődni vele. Ha bele gondolsz, a műszaki eszközöknek, programoknak bizony szüksége van
                    karbantartásra, frissítésre. Ahogyan a kocsidat elviszed a szervízbe vagy a telefonodra letöltöd a
                    frissítéseket, ugyanígy a honlapoddal is foglalkozni kell. Alábbiakban a legtipikusabb
                    problémákat soroljuk fel.
                </Typography>
                <Typography component="h3" variant="h4" gutterBottom>
                    Frissítésre szoruló honlapok jelei
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    <Box component="span" className="bold">Kinézet –</Box> Ez az, ami első pillantásra pozitív vagy negatív benyomást fog kelteni a látogatóban.
                    Egyrészt a technológia is fejlődik, másrészt a webdesignban is folyamatosan változnak a trendek,
                    így ránézésre is meg lehet állapítani egy oldalról, hogy az bizony régi, ódivatú. A szépség az
                    eléggé szubjektív fogalom, viszont a honlapodnak tükröznie kell a Te vállalkozásodat. Egy
                    babaholmikkal foglalkozó oldalnál a púderrózsaszín és a lepkék harmonikus hatást kelthetnek,
                    azonban, ha fémmegmunkáló gépeket forgalmazol, lehet nem ez a jó választás… Bármilyen
                    külsőt is választasz az legyen összhangban azzal, amit csinálsz, illetve azzal, amit szeretnél, ha
                    gondolnának rólad és a vállalkozásodról.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    A gyenge felhasználói élmény hatására sokan meggondolhatják vásárlási szándékukat. Másrészt
                    amennyiben vállalkozásod lógót, vagy arculatot váltott, azt a honlapod megjelenésében is le kell
                    követned, hiszen azt hihetik, hogy rossz oldalon járnak.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    <Box component="span" className="bold">Lassú betöltés –</Box> A mai rohanó világunkban senki se vár hosszú kínos másodpercekig arra, hogy
                    megjelenjen a honlapod. A gyenge teljesítmény pedig a keresőmotoroknak is szemet fog szúrni,
                    így pl. a Google is lejjebb rangsorolja a találatok között a lassabb oldalakat. Weblapod
                    lassúságának javítása érdekében csökkenteni, illetve optimalizálni lehet a képek, a videók
                    méretét, illetve megjelenítését.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    <Box component="span" className="bold">Nem mobilbarát megjelenítés –</Box> Manapság az okostelefonok világában az emberek egyre
                    inkább a mobiltelefonjukat használják a honlapok megtekintésére is, így ahhoz, hogy ne veszíts
                    ügyfeleket, elengedhetetlen az, hogy ne essen szét az oldalad a különböző képernyőméreteken
                    (reszponzivítás). A gyenge felhasználói élményt a keresőmotorok sem díjazzák és hátrébb
                    sorolnak a találatok közt.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    <Box component="span" className="bold">Elavult információk –</Box> Nem jó az e-mailcím, telefonszám vagy a boltod címe az oldaladon, így
                    nem talál meg az ügyfél. Már nem is azzal foglalkozol, ami a honlapodon szerepel. Az árak
                    manapság gyorsan változnak, így weblapodon is szükséges frissíteni.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    <Box component="span" className="bold">Friss tartalmak hiánya –</Box> Megfelelően optimalizált, friss tartalmak nem csak a keresőmotorokat
                    vonzzák, hanem az ügyfeleket is. Az emberek ritkán olvasnak öt évvel ezelőtti újdonságokat –
                    amik azóta már lehet nagyon is elavultak.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    <Box component="span" className="bold">Nem felhasználóbarát –</Box> Fontos, hogy milyen hatást gyakorolsz a honlapod látogatóira. Egy jó
                    honlapon könnyen és egyszerűen lehet navigálni, logikus és átlátható a felépítés. Hiába jó a
                    szolgáltatásod, ha a honlapodra tévedő emberke bosszankodva kattingat percekig, negatív
                    szájízzel fog távozni az oldaladról.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    <Box component="span" className="bold">Zsúfoltság –</Box> Egyrészt a letisztult, korszerű megjelenés része, hogy inkább képeket használ egy
                    oldal, másrészt a túl sok szövegben elveszik a lényeg. Az emberek rémesen türelmetlenek,
                    azonnal a lényeget keresik az oldaladon. A magyarázatokat, plusz infókat, rejtsd el, akár tedd a
                    „gyakran ismételt kérdések közé” lenyíló formában. A hosszú meséket pedig hagyd meg a blog
                    részeknek. Azok az emberek, akik igazán érdeklődnek a vállalkozásod, terméked iránt, szeretnék
                    látni a részleteket, azok szívesen olvasnak róla többet is, így a blogodat is aktívan lapozgatják.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    <Box component="span" className="bold">Nem mutatja be vállalkozásod fejlődését –</Box> Honlapod azt tartalmazza, ahol évekkel ezelőtt
                    álltál, és nem azt, ahol jelenleg tartasz. Kezdőként lehet egyedül pár termékkel, pár
                    szolgáltatással indítottál, de mára az évek során egy nagy csapattal a hátad mögött egyre több
                    mindent árulsz, egyre bonyolultabb feladatokat tudsz elvégezni. Vállalkozásod életében az évek
                    során sok minden változik, fejlődik. Akár módosíthattad a fókuszod, a célcsoportod körét, vagy
                    javíthattad marketing módszereidet is. A vállalkozásod fejlődését a weblapoddal is le kell
                    követni, hiszen az oldaladon keresztül érkezők az évekkel ezelőtti árukat, szolgáltatásokat fogják
                    keresni, és nem azt, amivel jelenleg foglalkozol.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    <Box component="span" className="bold">Nem létező, elavult linkek –</Box> keresőmotorok ez alapján is rangsorolnak, ezért a linkeket időről
                    időre át kell nézni.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    <Box component="span" className="bold">Fórumok moderációja, karbantartása –</Box> amennyiben weblapodon fórum található, ott
                    szükséges automatikus szűrőket használnod, és azokat időről időre frissíteni, különben kéretlen
                    üzenetek hada áraszthat el.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    <Box component="span" className="bold">Nincs összekötve a közösségi médiával –</Box> amennyiben Facebookon, Instagrammon stb. már
                    követőbázist építettél ki, akkor azt kösd össze a honlapoddal is.

                    Ha fentieket nem frissíted időről időre, akkor a honlapodon keresztül nem érkezik hozzád
                    megfelelő minőségű és mennyiségű vásárló. Keresőmotoroknak (SEO) nem fogsz megfelelni,
                    így ők sem fognak feléd forgalmat terelni. Így bevételtől fogsz elesni, de „nagyobb baj”
                    ezekkel nem ér, már ha neked az nem fontos, hogy a honlapod bevételt hozzon. Azonban ha
                    az alábbiak karbantartásával sem foglalkozol, akkor kiberbűnözőkkel és büntetésekkel kell
                    szembe nézned.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    <Box component="span" className="bold">Sablonokat nem frissíted –</Box> Amennyiben honlapod pl. a WordPresst használja, akkor szükséges
                    rendszeresen frissíteni a WordPress alapot, sablont és bővítményeket, különben honlapod
                    lelassulhat, működése akadozhat, illetve támadásoknak teszed ki az oldalad.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    <Box component="span" className="bold">Nem felel meg az adatvédelmi előírásoknak –</Box> A GDPR rendelet alkalmazása viszonylag új,
                    így az évekkel ezelőtt készített honlapok valószínűleg nem felelnek meg a követelményeknek.
                    Már nem elég az, hogy feltünteted, hogy az oldalad sütiket használ, hanem a felhasználónak
                    egyértelműen beleegyezését is kell adnia.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    <Box component="span" className="bold">Nem biztonságos az oldalad –</Box> a technológiák fejlődésével újabb és újabb biztonsági
                    protokollokat vezetnek be, amiknek az évekkel ezelőtt honlapok még nem feltétlenül felelnek
                    meg, így az adatszivárgás és visszaélések veszélye nagyobb.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    A fentieken felül, van még egy nyomos ok, arra, hogy ideje frissíteni a honlapod:
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    <Box component="span" className="bold">Ha már nem tetszik, amit látsz, ha nem örömmel és büszkeséggel tölt el a weblapod, ha úgy
                    érzed, hogy már nem képvisel téged, vagy a vállalkozásodat, ha szégyenled másoknak
                    megmutatni, vagy csak a versenytársaidé sokkal szebb, akkor ne hagyd, hogy ezek az
                        érzések kínozzanak, hanem frissítsd a honlapod.</Box>
                </Typography>
                <Typography component="h3" variant="h4" gutterBottom>
                    Milyen gyakran szükséges frissíteni, illetve rendszeresen vagy alkalmanként
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Alapvető ökölszabályokat nehéz megfogalmazni.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Amint láthattad a fenti felsorolásban sok minden felmerülhet a honlapod használata közben.
                    Nehéz szabályokat felállítani.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Vannak egyedi, egyszer-egyszer felmerülő kérdések, ezeket hívhatjuk egyedi karbantartási
                    szükségleteknek.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Ha technikai hibáid vannak (lassú betöltés, nem responzív), ha nem biztonságos az oldalad,
                    támadás érte stb., akkor ne habozz, ne várj be más frissítési igényt.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Amennyiben profilt váltottál, bővítettél, megváltozott a vállalkozásod külső megjelenése, már az
                    első marketing kampány előtt 2-3 hónappal kezd el honlapod ráncfelvarrását is.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Rendszeres frissítések közül a sablonok és a biztonsági frissítések mellett a keresőoptimalizálást
                    kell kiemelni.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Ha sablonokat használsz, akkor minimum fél évente érdemes megnézni, hogy milyen frissítések
                    jelentek meg. Egyedi honlapoknál lehet ritkábban foglalkozni ezzel.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Az, hogy milyen sűrűn szükséges módosítani a honlapodon, egyrészt attól függ, hogy a
                    vállalkozásod milyen gyorsan fejlődik, illetve, hogy mennyire dinamikusan változik az iparágad
                    (hiszen, hogy versenyben maradj le kell követned a versenytársak fejlesztéseit).
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Érdemes hosszú távú kapcsolatot kiépíteni a honlapodat készítő vállalkozással – akár havi
                    rendelkezésre állás formájában – hogy a kisebb-nagyobb problémákat, ráncfelvarrásokat azonnal
                    orvosolni lehessen. Illetve, hogy a további fejlesztési irányokat időben be lehessen tervezni, és ne
                    azért ne tudj elindítani egy marketing kampányt, mert az újonnan felkért fejlesztőd még a
                    honlapod felépítésével ismerkedik, így sokkal több időbe (és pénzbe) fog kerülni ez neked.
                </Typography>
                <Typography component="h3" variant="h4" gutterBottom>
                    Régi honlap ráncfelvarrása vagy új honlap készítése
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Apró módosításokat, folyamatos frissítéseket a honlapodon addig lehet végrehajtani, amíg azok
                    még technikailag megoldhatóak.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Amennyiben a holnapodon teljes arculatot váltasz, akkor mérlegelni kell, hogy mi éri meg
                    jobban. Informatikusoddal ki tudjátok számolni, hogy mennyi idő (és pénz), ha a régi oldalt
                    fejlesztitek tovább, vagy ha egy új oldal készül.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    A ráncfelvarrásokkal, frissítésekkel a probléma akkor adódik, ha a weblap technológiailag nem
                    támogatott, nem kompatibilis elemeket használ.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Alapvetően azt kell látnod, hogyha honlapodat folyamatosan karban tartod, akkor sok évig
                    nem kell lecserélned. Viszont, ha 2-3 éve nem nyúlt senki hozzá, akkor már valószínűleg
                    technológiailag egyszerűbb (és olcsóbb is) lecserélni az egész oldalt, mint toldozni-foltozni.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Összefoglalásul kiemelkedően fontos, hogy rendszeresen frissítsd weboldalaidat, hogy fenntartsd
                    a biztonságot, fokozd a felhasználói élményt és optimalizáld a SEO-t (keresőoptimalizálás), így
                    honlapod több ügyfelet hozhat számodra. A rendszeres karbantartással weblapod tovább
                    használható marad, és a jövőbeni frissítések is összességében olcsóbbak lesznek.
                </Typography>
                <Typography component="p" variant="body1" className="hero1" sx={{fontWeight: 'bold'}} gutterBottom>
                    Itt az ideje a honlapauditnak! Nézzük át együtt honlapodat, és találjuk meg a számodra
                    legkedvezőbb megoldásokat.
                </Typography>
            </Container>

            <Footer/>
        </>
    )
}

export default BlogRefresh
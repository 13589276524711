import {motion, useScroll} from "framer-motion";
import {Helmet} from "react-helmet";
import React from "react";
import Footer from "./Footer";
import {Box, Container, Typography} from "@mui/material";
import Header from "./Header";
import BlogItem from "./BlogItem";

function Blog() {

    const { scrollYProgress } = useScroll();

    return(
        <>
            <Helmet>
                <title>Netbrain - Rólunk</title>
                <meta name="description" content="netbrain egyedi weboldal fejlesztés" />
            </Helmet>
            <motion.div
                className="progress-bar"
                style={{ scaleX: scrollYProgress }}
            />
            <Header/>
            <Container sx={{marginY: "100px", width: '100vw'}} className="blog-page">
                <Typography component="h1"  variant="h2" className="hero1" sx={{fontWeight: 'bold'}} gutterBottom>
                    Blog
                </Typography>
                <BlogItem/>
            </Container>
            <Footer/>
        </>
    )
}

export default Blog
import {Box, IconButton, Container, Menu, MenuItem, Stack, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import React, { useState, MouseEvent } from "react";
import MenuIcon from '@mui/icons-material/Menu';

function Header() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <Container className="header" maxWidth={false} sx={{width: '100vw', overflow: 'hidden',  marginX: 0}}>
            <Container sx={{ marginY: '10px', width: '100%'}}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Box component="div" style={{ display: 'flex' }}>
                        <a href="/" style={{ display: 'flex', alignItems: 'center' }}>
                            <Box component="img" src="/icon.png" width="100" alt="Netbrain Logo" className="icon" />
                            <Typography
                                component="h2"
                                variant="h3"
                                className="hero1"
                                sx={{ paddingLeft: '10px', fontSize: { xs: '1.5rem', sm: '2rem' } }}
                            >
                                NetBrain
                            </Typography>
                        </a>
                    </Box>
                    <Box component="div" sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <a href="/blog.html">
                            <Typography component="h2" variant="h5" className="hero1" sx={{ paddingX: '10px' }}>Blog</Typography>
                        </a>
                        <a href="/rolunk.html">
                            <Typography component="h2" variant="h5" className="hero1" sx={{ paddingX: '10px' }}>Rólunk</Typography>
                        </a>
                    </Box>
                    <Box component="div" sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={handleMenuOpen}
                        >
                            <MenuIcon sx={{color: '#54abc9'}} />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleMenuClose}>
                                <a href="/blog.html" style={{ textDecoration: 'none', color: 'inherit' }}>Blog</a>
                            </MenuItem>
                            <MenuItem onClick={handleMenuClose}>
                                <a href="/rolunk.html" style={{ textDecoration: 'none', color: 'inherit' }}>Rólunk</a>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Stack>
            </Container>
        </Container>
    );
}

export default Header
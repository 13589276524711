import {Container, Grid, Box, Typography} from "@mui/material";
import {motion, useAnimation, Variants} from "framer-motion";
import { useInView } from 'react-intersection-observer';
import React, {ReactNode} from "react";


function BlogItem() {

    const divVariants: Variants = {
        hidden: { x: '100%', opacity: 0 },
        visible: { x: 0, opacity: 1, transition: { duration: 0.5 } },
    };

    interface DivSectionProps {
        children: ReactNode;
    }

    const DivSection: React.FC<DivSectionProps> = ({ children }) => {
        const controls = useAnimation();
        const [ref, inView] = useInView({
            triggerOnce: true,
            threshold: 0.1
        });

        React.useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        return (
            <Grid item xs={12} sm={6}>
                <Box
                    component="div" //component={motion.div}
                    ref={ref}
                    //initial="hidden"
                    //animate={controls}
                    //variants={divVariants}
                    className="service-item"
                    //sx={{margin: '20px'}}
                 >
                    {children}
                </Box>
            </Grid>
        );
    };
    return(
        <>
            <div className="">
                <Container id="blog" className="blog" sx={{width: '100vw'}}>
                    <Grid container marginY={2}>
                        <DivSection>
                            <Typography component="h2" variant="h4" className="hero1" gutterBottom >
                                Landing oldalra vagy weboldalra van szükséged?
                            </Typography>
                            <Box component="img" src="/blog_landing_web.jpg" width="70%" className="logo" sx={{marginY: '20px'}}/>
                            <Typography component="p" variant="h6" gutterBottom >
                                Ebben a blogban először is tisztába tesszük, hogy mi a különbség a landing és a
                                weboldal között, megnézzük melyiknek mi a célja. Végig vesszük a landing oldalak
                                fő típusait. Kiszámoljuk hány landing oldalra lehet szükséged. A cikk végére
                                látni fogod, hogy a landing oldal és a weboldal nem helyettesíti egymást, így
                                mindkettőre szüksége lesz egy vállalkozásnak hosszú távon.
                            </Typography>
                            <a href="/landing_vagy_weboldal.html" style={{textDecoration: 'none'}}><Typography component="p" variant="h5" sx={{color: '#54abc9', margin: '2rem'}}>Tovább a cikkre...</Typography></a>
                        </DivSection>
                        <DivSection>
                            <Typography component="h2" variant="h4" className="hero1" gutterBottom >
                                Mennyibe kerül 2024-ben egy weblap Magyarországon?
                            </Typography>
                            <Box component="img" src="/blog_cost.jpg" width="70%" className="logo" sx={{marginY: '20px'}}/>
                            <Typography component="p" variant="h6" gutterBottom>
                                Gyakran találkozni azzal a kérdéssel, hogy mennyiből lehet elkészíteni egy honlapot.
                                Nem könnyű egy konkrét számot meghatározni, de sorra tudjuk venni, hogy
                                milyen tényezőket vegyél figyelembe, mikor szakembert választasz.
                            </Typography>
                            <a href="/mennyibe_kerul_egy_weblap.html" style={{textDecoration: 'none'}}><Typography component="p" variant="h5" sx={{color: '#54abc9', margin: '2rem'}}>Tovább a cikkre...</Typography></a>
                        </DivSection>
                        <DivSection>
                            <Typography component="h2" variant="h4" className="hero1" gutterBottom >
                                Teljesen ingyenes weboldal készítés lehetséges? Ingyen honlap készítés programozói tudás
                                nélkül? Mire használható az ingyenes weblap?
                            </Typography>
                            <Box component="img" src="/blog_free.jpg" width="70%" className="logo" sx={{marginY: '20px'}}/>
                            <Typography component="p" variant="h6" gutterBottom >
                                Cikkünkben megmutatjuk, hogy az ingyenesen elkészített honlapoknak valójában milyen
                                költségei vannak. Szó lesz a legnépszerűbb ingyenes tartalom kezelő rendszerről, a
                                WordPressről, amely akár programozói tudás nélkül is használható. Megnézzük milyen előnyei
                                és hátrányai vannak ezeknek az ingyenes sablon webodalaknak, illetve az egyedi készítésű
                                honlapoknak. Példákat hozunk arra, hogy kiknek ajánljuk, hogy saját maguk összeállítsanak egy
                                honlapot, és milyen esetekben javasoljuk, hogy inkább szakemberhez forduljatok.
                            </Typography>
                            <a href="/ingyenes_weblap.html" style={{textDecoration: 'none'}}><Typography component="p" variant="h5" sx={{color: '#54abc9', margin: '2rem'}}>Tovább a cikkre...</Typography></a>
                        </DivSection>
                        <DivSection>
                            <Typography component="h2" variant="h4" className="hero1" gutterBottom >
                                Miért kell rendszeres honlap karbantartás? Veszélyes is lehet egy nem frissített weblap?
                                Rendszeres vagy alkalmi frissítésre van szükség? Weboldal felújítást vagy egy új készítését
                                válaszd?
                            </Typography>
                            <Box component="img" src="/blog_refresh.jpg" width="70%" className="logo" sx={{marginY: '20px'}}/>
                            <Typography component="p" variant="h6" gutterBottom>
                                Ebben a cikkben végignézzük, hogy mikor szorul a holnapod karbantartásra, illetve az alábbi
                                kérdésekre keressük a választ: Van-e valami szabálya a rendszeres, illetve az alkalmi
                                frissítésnek? Milyen veszélyeket rejthet egy elavult honlap? Mikor érdemes új weboldalban
                                gondolkodni? Évente új honlap kell? Meddig jó egyáltalán egy honlap?
                            </Typography>
                            <a href="/weblap_frissites.html" style={{textDecoration: 'none'}}><Typography component="p" variant="h5" sx={{color: '#54abc9', margin: '2rem'}}>Tovább a cikkre...</Typography></a>
                        </DivSection>
                    </Grid>
                </Container>
            </div>
        </>
    )
}

export default BlogItem
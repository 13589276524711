import * as React from "react";
import {Box, Container, Stack, Typography} from "@mui/material";
import {motion, AnimatePresence, Variants, useAnimation} from "framer-motion";
import {useState} from "react";
import {useInView} from "react-intersection-observer";

export const FAQ = () => {
    const variantText: Variants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 , transition: { duration: 2.8 } },
    };

    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1
    });

    React.useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    return(
        <>
            <Container maxWidth={false} className="container" sx={{backgroundColor: "#54abc9"}}>
                <Container className="faq">
                    <Stack direction={{xs: 'column', md: 'row' }} spacing={4}>
                        <Box component="img" src="/help.jpg" alt="Netbrain" className="logo" />
                        <div>

                            <Typography component={motion.h2}
                                        variant={"h2"}
                                        variants={variantText}
                                        ref={ref}
                                        initial="hidden"
                                        animate={controls}
                                        gutterBottom
                                        sx={{color: 'white'}}
                            >Legyen honlapod innovatív, egyedi és kreatív! Segítünk:
                            </Typography>
                            <ul className="footer-widgets__list">
                                <li><Typography component="p" variant={"h5"}>Design audit</Typography>
                                </li>
                                <li><Typography component="p" variant={"h5"}>Hibakeresés</Typography></li>
                                <li><Typography component="p" variant={"h5"}>Keresőoptimalizált szövegírás</Typography></li>
                                <li><Typography component="p" variant={"h5"}>Weboldal felújítás</Typography></li>
                                <li><Typography component="p" variant={"h5"}>Egyedi igényeknek megfelelő szoftverfejlesztések</Typography>
                                </li>
                            </ul>
                        </div>
                    </Stack>
                </Container>
            </Container>
        </>
    )
}

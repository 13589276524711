import {motion, useScroll} from "framer-motion";
import {Helmet} from "react-helmet";
import React from "react";
import Footer from "./Footer";
import {Box, Container, Typography} from "@mui/material";
import Header from "./Header";

function BlogLandingVsWebpage() {

    const { scrollYProgress } = useScroll();

    return(
        <>
            <Helmet>
                <title>Netbrain Blog - Weblap frissítés</title>
                <meta name="description" content="netbrain egyedi weboldal fejlesztés" />
            </Helmet>
            <motion.div
                className="progress-bar"
                style={{ scaleX: scrollYProgress }}
            />
            <Header/>
            <Container className="blog-page">
                <Typography component="h1" variant="h4" gutterBottom>
                    Landing oldalra vagy weboldalra van szükséged?
                </Typography>
                <Typography component="p" variant="body1" gutterBottom className="hero1">
                    Ebben a blogban először is tisztába tesszük, hogy mi a különbség a landing és a
                    weboldal között, megnézzük melyiknek mi a célja. Végig vesszük a landing oldalak
                    fő típusait. Kiszámoljuk hány landing oldalra lehet szükséged. A cikk végére látni
                    fogod, hogy a landing oldal és a weboldal nem helyettesíti egymást, így mindkettőre
                    szüksége lesz egy vállalkozásnak hosszú távon.
                </Typography>
                <Box className="center">
                    <Box component="img" src="/blog_landing_web.jpg" className="blog-image"/>
                </Box>
                <Typography component="h3" variant="h4" gutterBottom>
                    Landing oldal
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    A landing page vagy landing oldal, az tulajdonképpen egy marketing eszköz, egy konkrét
                    céloldal, ahova érkeznek („landolnak”) pl. a hírlevelekkel, hirdetésekkel ide irányított
                    ügyfelek. Ennek az oldalnak <Box component="span" className="bold">egyetlen célja van</Box>, itt
                    <Box component="span" className="bold">egyetlen konkrét cselekvésre</Box>
                    (feliratkozás, ajánlatkérés, vásárlás) fogod bíztatni az oldalra látogatókat.
                    Ezeken az oldalokon a látogató máshova nem tud navigálni, nincsenek kifele vezető linkek,
                    további információk.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Így aki ide került két dolgot tud csinálni: vagy végrehajtja a kért cselekvést,
                    vagy nem és bezárja az oldalt.
                </Typography>
                <Typography component="h3" variant="h4" gutterBottom>
                    Weboldal
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Eközben egy weboldal célja ennél összetettebb, és bár lehet egy oldalas is, de ennek
                    ellenére a célja különbözik a landing oldaltól.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Egy weboldalon magát a vállalkozásodat, a korábbi munkáidat és a teljes portfóliódat is
                    bemutathatod. Minden olyan információt megoszthatsz, ami az ügyfeleidet, leendő ügyfeleidet
                    érdekelheti, ami növeli feléd a bizalmát és segítség nekik a vásárlás előtt vagy után, ahol
                    az ide látogató időpontot foglalhat, visszajelzéseket adhat stb.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    <Box component="span" className="bold">Weboldal legfontosabb célja az információ átadás,
                        emellett a márkaépítés, a megbízhatóság, a hitelesség növelése és az értékadás,
                        valamint a funkcionalitás is</Box> (a webshoptól kezdve, az ügyfélszolgálaton át, a fórumokig).
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Természetesen itt is vannak vásárlásra, ajánlatkérésre, feliratkozásra buzdító részek,
                    de nem kizárólag egy konkrét cselekvésre ösztönző felhívás jelenik meg, és lehet egy
                    honlapon belül többféle szolgáltatásra/termékre, többféle cselekvésre ösztönző felhívás is.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Pont ezért, mivel itt a hirdetések tárgyához képest más információkkal is találkoznak a
                    honlapra látogatók, legtöbbször nem ide szokták becsatornázni a hirdetésekből az embereket,
                    hanem a landing oldalra, ami nem tereli el a látogatók figyelmét, mivel csak az adott
                    cselekvésre ösztönző elemeket tartalmaz.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Egy weboldal esetében fontos a kereső optimalizálás, amivel organikusan (tehát fizetett
                    marketing kampány nélkül is) megtalálnak a potenciális ügyfelek. (Ezzel ellentétben a
                    landing oldalakat elérhetetlenné szokás tenni a keresők előtt, egyrészt azért, hogy
                    pontosabban mérhető legyen a marketing kampányod teljesítménye, másrészt így nem zavarja
                    meg a weboldalad rangsorolását.)
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Weblapodhoz csatlakozhat több aloldal is, és természetesen több landing oldal is.
                </Typography>
                <Typography component="h3" variant="h4" gutterBottom>
                    Akkor most kell egy landing oldal?
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Igen, amennyiben van bármi marketing eszközöd, amiből konkrét céllal terelsz át ügyfeleket
                    (tehát nem általános “ismerj meg” céllal, hanem egy pontos tevékenységre akarod ösztönözni őket),
                    akkor kell, hogy legyen landing oldalad.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Viszont, nem egy landing oldalra van szükséged (persze egy is jobb a nullánál), hanem többre.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Mivel egy landing oldalon egy konkrét cselekvésre buzdítod az arra irányított ügyfeleket,
                    ezért, ha pl. többféle hirdetésed fut, ha különböző célcsoportokat céloznál meg, akkor több
                    landing oldal kell.
                </Typography>
                <Typography component="h3" variant="h4" gutterBottom>
                    Hány darab landing oldalra lehet szükségem?
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Ehhez előbb nézzük végig hány féle landing page-t tudunk megkülönböztetni a cselekvés alapján.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    <Box component="span" className="bold">Email cím gyűjtő oldal – </Box>Ez lehet regisztráció
                    valami ingyenes tananyagra, letölthető videóra, feliratkozás valamilyen értesítésekre,
                    hírlevélre stb. Ezek a legegyszerűbb landoló oldalak, és itt általában csak nevet és
                    e-mail címet gyűjtünk.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    <Box component="span" className="bold">Ajánlatkérő oldal – </Box>Ez már olyan oldal, ahol
                    bár nem adsz el konkrétan valamit, de olyanok vehetik fel veled a kapcsolatot,
                    akik potenciális ügyfelek lehetnek. Vagyis komoly érdeklődőket gyűjtesz, tipikusan
                    a „kérj árajánlatot” felhívással. Itt már nem csak e-mail címet kérünk az ide
                    látogatótól, hanem további adatokat is.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    <Box component="span" className="bold">Sales oldal – </Box>Itt a cél valami eladása,
                    bármilyen szolgáltatás, termék, csomag, amit meg lehet venni, vagy meg lehet rendelni tőled.
                    Ez lehet ingyenes is, pl. valamilyen szolgáltatás ingyenes próbaidőszaka.
                    Ezen az oldalon nem információkat adunk, hanem lebontjuk a gátakat, amik akadályozzák
                    a vásárlást, egyértelműen kommunikáljuk a hasznokat, előnyöket, megoldásokat.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Landing oldaladat célzottabbá tudod tenni, ha az adott célközönségnek szólnak.
                    Nem ugyanazon ügyfelek érkeznek egy konkrét Goggle Ads hirdetésen vagy egy social
                    média felületen (az utóbbira a többség szórakozni és nem dolgozni jár), és
                    nem ugyanúgy kell megszólítanod a Facebookról illetve a Tiktokról érkezőket,
                    hiszen alapvetően különböző korosztály használja a felületeket. És ezt sorolhatnánk még tovább…
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Tehát a jobb eredmény eléréséhez több landing oldalra van
                        szükséged, és <Box component="span" className="bold">a különböző forrásról érkezőket, a különböző célközönségeket más-más
                        landing oldallal érdemes megcéloznod.</Box>
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Már így is sok lehetséges landing oldalnál tartunk, pedig akkor még nem is beszéltünk az A / B
                    tesztelésről, ami szükséges ahhoz, hogy megtaláld a legjobb sikereket hozó landing oldalt.
                    Miről is szól az <Box component="span" className="bold">A / B teszt</Box>? Létrehozol két landing oldalt, ami csak 1-1 elemben különbözik
                    és hol az egyik, hol a másik oldalra tereled az embereket. A jobb eredményeket elérő oldalt
                    megtartod és abból csinálsz egy újabb verziót egy újabb módosítással. És így haladsz
                    folyamatosan, a nagyobb részletek felől (pl. elrendezés, ajánlat szövege) az apróságokig,
                    amíg megtalálod, hogy mivel tudod a lehető legtöbb bevételt szerezni.
                </Typography>
                <Typography component="h3" variant="h4" gutterBottom>
                    Weboldal vagy landing oldal kell?
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Röviden: mindkettő.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Ma már egy vállalkozás hitelességét erősen megkérdőjelezi, ha nincs weboldala. Ha
                    belegondolsz, hogyan ellenörzől le egy vállalkozást? „Megguglizod”. Vagyis akit a
                    kereső nem talál meg, az nem is létezik.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Megnyugodhatsz, nem csak te vagy óvatos a vásárlásaid során, hanem az emberek többsége is
                    igyekszik le ellenőrizni az eladót. A vásárlóik nagy része a weblap alapján dönti el,
                    hogy mennyire megbízható az adott vállalkozás, sőt, az online vásárlók 50%-a kapcsolódó
                    videót keres az adott termékről mielőtt megvenné azt.<br/>
                    <a href="https://techreport.com/statistics/finance-marketing/landing-page-statistics/" target="_blank" style={{color: '#61dafb'}}><Box component="span" className="italic">(forrás: https://techreport.com/statistics/finance-marketing/landing-page-statistics/ )</Box></a>
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    2024-re már több, mint 1 milliárd honlap létezik, a vállalkozások 71 %-nak van honlapja.
                    És a leggyakrabban használt webhely (2023-ban 85 milliárd látogató) a Google, innen ered a
                    globális online forgalom 92 %-a.<br/>
                    <a href="https://www.forbes.com/advisor/business/software/website-statistics/" target="_blank" style={{color: '#61dafb'}}><Box component="span" className="italic">(forrás: https://www.forbes.com/advisor/business/software/website-statistics/ )</Box></a>
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    A landing oldal nem helyettesíti weblapot, hiszen ez egy marketing eszköz, inkább kiegészítik
                    egymást, mindkettő szükséges egy vállalkozás számára.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Kezdő kisvállalkozásoknál lehet olyan élethelyzet, ahol érdemes előbb egy landing oldallal
                    kezdeni, és majd jöhet a weblap. Viszont van, akinél érdemesebb a weblappal kezdeni, és
                    aztán amikor elindítod a hirdetési kampányodat, arra célzottan landing oldalt/oldalakat készíteni.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    Öszvér megoldásként léteznek olyan weboldalak, amelyeknek a fő oldala tulajdonképpen egy
                    navigációs panellel és lábléccel kibővített landing oldal, de ezesetben a konverziós mutatóid
                    alacsonyabbak lesznek, mintha külön landing oldalt használtál volna. Ez a megoldás is megfelelő
                    lehet annak a kezdő vállalkozásnak, amelyik csak egyféle terméket kíván eladni.
                </Typography>
                <Typography component="p" variant="body1" gutterBottom>
                    <Box component="span" className="bold">Összegzésül azt emelhetjük ki, hogy nem lehet általános
                        receptet adni, viszont az biztos, hogy a hosszú távú sikerekhez külön weboldalra és landing
                        oldalakra is szükséged lesz.</Box>
                </Typography>
                <br/>
            </Container>

            <Footer/>
        </>
    )
}

export default BlogLandingVsWebpage
import {motion, useAnimation, useScroll, Variants} from "framer-motion";
import {Helmet} from "react-helmet";
import React from "react";
import Footer from "./Footer";
import {Box, Container, Stack, Typography} from "@mui/material";
import Header from "./Header";
import {useInView} from "react-intersection-observer";

function AboutUs() {

    const { scrollYProgress } = useScroll();

    const divVariants: Variants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 2.0 } },
    };

    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.1
    });

    React.useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    return(
        <>
            <Helmet>
                <title>Netbrain - Rólunk</title>
                <meta name="description" content="netbrain egyedi weboldal fejlesztés" />
            </Helmet>
            <motion.div
                className="progress-bar"
                style={{ scaleX: scrollYProgress }}
            />
            <Header/>
            <Container sx={{marginTop: "100px", marginBottom: "20px"}} className="blog-page">
                <Stack direction={{xs: 'column', md: 'row' }} spacing={4} alignItems="center">
                    <motion.div
                        ref={ref}
                        initial="hidden"
                        animate={controls}
                        variants={divVariants}
                    >
                        <Stack direction="column" spacing={3} className="">
                            <Typography component="h1" variant="h2" className="hero1" sx={{fontWeight: 'bold'}}>
                                Üdvözöllek,
                            </Typography>
                            <Typography component="h5" variant="h5" >
                                <span className="">Pap Gábor vagyok, már több, mint 20 éve foglalkozom programozással.</span>
                            </Typography>
                            <Typography component="h4" variant="h5" className="">A <span style={{fontWeight: 'bold'}}>netbrain.hu</span>-t azért indítottam el,
                                hogy segítséget nyújtsak azon kisvállalkozásoknak, akik fejlődésük során megakadtak az
                                informatika útvesztőjében és a további növekedésüket már nem szolgálja a jelenleg
                                rendelkezésükre álló informatikai palettájuk, hanem már akadályt jelent, ami gyakran
                                a hatékonyság romlásában, a lehetőségekhez képest magas költségszintben és alacsonyabb bevételben is megjelenik.
                            </Typography>
                        </Stack>
                    </motion.div>
                    <Box component="img" src="/pg.png" alt="about" className="logo hero-logo" />
                </Stack>
                <Box component="div" >
                    <Typography component="h4" variant="h5" sx={{marginY: '20px'}}>Kis túlzással ma már mindenki saját maga is tud honlapot készíteni,
                        számtalan olcsóbbnál olcsóbb megoldások léteznek, amik egy induló vállalkozásnak megfelelnek. Sok folyamatra létezik
                        már külön-külön olcsó „dobozos” megoldás is. Aztán ahogy növekedsz, egyre több kollégád lesz és egyre újabb és
                        újabb informatikai megoldások merülnek fel, és eljön az a pont, amikor felismered, hogy jó volna a honlapodat
                        összekötni a rendeléssel, a raktárral, automatikus pontokat beépíteni a napi munkafolyamatokba, vezetői
                        kimutatásokat készíttetni, figyelni hol vannak megakadások a munkamenetben stb. Vagyis rendszereket,
                        folyamatokat összekötni, integrálni, optimalizálni.<br/> A honlapkészítés mellett ebben is segítségedre lehet a <span style={{fontWeight: 'bold'}}>NetBrain</span>.
                    </Typography>
                    <Typography component="h4" variant="h5" className="hero1" sx={{fontWeight: 'bold'}}>
                        Írd meg mi a problémád, és nézzük át együtt mivel tudunk hozzájárulni céged sikeréhez.
                    </Typography>
                </Box>
            </Container>
            <Footer/>
        </>
    )
}

export default AboutUs